/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Popup from '@scandipwa/scandipwa/src/component/Popup';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import AddToCart from 'Component/AddToCart';
import { ADD_TO_CART_LIMITED, ADD_TO_CART_POPUP_ID_PREFIX } from 'Component/AddToCartPopup/AddToCartPopup.config';
import Link from 'Component/Link';
import ProductPrice from 'Component/ProductPrice';
import { ProductType } from '@scandipwa/scandipwa/src/type/ProductList.type';
import { getProductPrice } from 'Util/Product';

import './AddToCartPopup.style';

/** @namespace Pwa/Component/AddToCartPopup/Component/AddToCartPopupComponent */
export class AddToCartPopupComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        popupRef: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
        onContinueShopping: PropTypes.func.isRequired,
        onGotoCart: PropTypes.func.isRequired, // eslint-disable-next-line react/boolean-prop-naming
        fromDC: PropTypes.bool.isRequired
    };

    renderAddToCart(item) {
        const configurableVariantIndex = -1;
        const quantity = 1;
        const groupedProductQuantity = {};
        const productOptionsData = {};

        return (
            <AddToCart
              product={ item }
              mix={ { block: 'ProductActions', elem: 'AddToCart' } }
              className="linkedProduct"
              fromPopup
              configurableVariantIndex={ configurableVariantIndex }
              quantity={ quantity }
              groupedProductQuantity={ groupedProductQuantity }
              productOptionsData={ productOptionsData }
            />
        );
    }

    filterSellable = (desc) => {
        switch (desc) {
        case 'Cameretta componibile':
        case 'Cucina componibile':
            return false;
        default:
            return true;
        }
    };

    goToHome = () => {
        window.location.href = '/';
    };

    render() {
        const {
            product, product: {
                sku, mc_color_information, linkedProducts
            },
            popupRef, onContinueShopping, onGotoCart, fromDC
        } = this.props;

        const { description } = mc_color_information || {};

        if (linkedProducts) {
            // eslint-disable-next-line radix
            const linkedItems = linkedProducts.filter((item) => this.filterSellable(item.mc_inline_description));

            if (linkedItems.length > 0) {
                return (
                    <>
                <Popup
                  isSidePopup
                  popupRef={ popupRef }
                  customClass="newAddToCart"
                  clickOutside={ false }
                  id={ `${ADD_TO_CART_POPUP_ID_PREFIX}_${sku}` }
                >
                            <div block="AddToCartPopupNew">
                                <div className="Container">
                                    <i className="fa fa-check" aria-hidden="true" />
                                    <div block="AddToCartPopup" elem="title">
                                        <h2 block="AddToCartPopup" elem="productName">{ product.name }</h2>
                                        <p className="productDesc">{ product.mc_inline_description }</p>
                                        <p className="colorDesc">{ description }</p>
                                        <p className="cartText">{ __('È stato aggiunto al carrello!') }</p>
                                        <p className="actions" onClick={ onGotoCart }>{ __('Go to Cart') }</p>
                                        <p className="actions" onClick={ onContinueShopping }>{ __('Continue Shopping') }</p>
                                    </div>
                                </div>
                            </div>
                            <div className="buyAlso">
                                { linkedItems.length > 0 && (<h3>{ __('Completa il tuo arredo con') }</h3>) }
                                { linkedItems.map((item) => (
                                    <div className="card">
                                        <Link to={ item.url }>
                                            <img src={ item.small_image.url } alt={ item.name } />
                                        </Link>
                                        <Link to={ item.url }>
                                            <div>
                                                <h1 className="linkedName">{ item.name }</h1>
                                                <p className="linkedDesc">{ item.mc_inline_description }</p>
                                                <p className="linkedMeasures">
                                                    { `cm ${item.mc_dimension_width} x ${item.mc_dimension_depth} x ${item.mc_dimension_height}` }
                                                </p>
                                                <ProductPrice promoPosition="popUpAddToCart" price={ getProductPrice(item) } />
                                            </div>
                                        </Link>
                                        { this.renderAddToCart(item) }
                                    </div>
                                )) }
                            </div>
                </Popup>
                    <Popup
                      popupRef={ popupRef }
                      customClass="limitedItem"
                      id={ `${ADD_TO_CART_LIMITED}_${sku}` }
                    >
                            { __('Hai raggiunto il limite massimo di pezzi acquistabili per questo prodotto') }
                    </Popup>
                    </>
                );
            }
        }

        return (
            <Popup
              popupRef={ popupRef }
              id={ `${ADD_TO_CART_POPUP_ID_PREFIX}_${sku}` }
            >
                <div block="AddToCartPopup">
                    <div block="AddToCartPopup" elem="title">
                        { __('The product') }
                        &nbsp;
                        <span block="AddToCartPopup" elem="productName">{ product.name }</span>
                        &nbsp;
                        { __('was added to your cart') }
                    </div>

                    <div block="AddToCartPopup" elem="actions">
                        <button className="Button" onClick={ fromDC ? this.goToHome : onContinueShopping }>
                            <span>{ __('Continue Shopping') }</span>
                        </button>

                        <button className="Button" onClick={ onGotoCart }>
                            <span>{ __('Go to Cart') }</span>
                        </button>
                    </div>
                </div>
            </Popup>
        );
    }
}

export default AddToCartPopupComponent;
