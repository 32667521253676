// import PropTypes from 'prop-types';
import { ProductType } from '@scandipwa/scandipwa/src/type/ProductList.type';
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import history from 'Util/History';

import AddToCartPopup from './AddToCartPopup.component';

/** @namespace Pwa/Component/AddToCartPopup/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

/** @namespace Pwa/Component/AddToCartPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

/** @namespace Pwa/Component/AddToCartPopup/Container/AddToCartPopupContainer */
export class AddToCartPopupContainer extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        // eslint-disable-next-line react/boolean-prop-naming
        fromDC: PropTypes.bool.isRequired

    };

    containerFunctions = {
        onContinueShopping: () => {
            this.closePopup();
        },
        onGotoCart: () => {
            this.closePopup();
            history.push('/cart');
        }
    };

    componentWillUnmount() {
        this.closePopup();
    }

    __construct(props) {
        super.__construct(props);

        this.popupRef = createRef();
    }

    _getProduct() {
        const { product } = this.props;
        return product;
    }

    closePopup() {
        this.popupRef?.current?.hidePopUp();
    }

    _getfromDC() {
        const { fromDC } = this.props;
        return fromDC;
    }

    containerProps = () => ({
        popupRef: this.popupRef,
        product: this._getProduct(),
        // eslint-disable-next-line react/destructuring-assignment
        fromDC: this._getfromDC()
    });

    render() {
        return (
            <AddToCartPopup
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartPopupContainer);
