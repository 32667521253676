/* eslint-disable max-len */
/* eslint-disable no-undef */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ADD_TO_CART_LIMITED, ADD_TO_CART_POPUP_ID_PREFIX } from 'Component/AddToCartPopup/AddToCartPopup.config';
import { CATEGORY_ID_ANGOLO_DELLE_OCCASIONI } from 'Route/AngoloDelleOccasioni/AngoloDelleOccasioni.config';
import {
    AddToCartContainer as SourceAddToCartContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/AddToCart/AddToCart.container';
import { decreaseLoading } from 'Store/Configuratore/Configuratore.action';
import { showPopup } from 'Store/Popup/Popup.action';
import BrowserDatabase from '@scandipwa/scandipwa/src/util/BrowserDatabase/BrowserDatabase';

import AddToCart from './AddToCart.component';

export const GTMDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/GTM/GTM.dispatcher'
);

/** @namespace Pwa/Component/AddToCart/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isLoading: state.ConfiguratoreReducer.isLoading
});

/** @namespace Pwa/Component/AddToCart/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showPopup: (id, payload) => dispatch(showPopup(id, payload)),
    pushAddToCart: (product, list) => GTMDispatcher.then(
        ({ default: dispatcher }) => dispatcher.pushAddToCart(dispatch, product, list)
    ),
    decreaseLoading: () => dispatch(decreaseLoading())

});

/** @namespace Pwa/Component/AddToCart/Container/AddToCartContainer */
export class AddToCartContainer extends SourceAddToCartContainer {
    static propTypes = {
        ...SourceAddToCartContainer.propTypes,
        list: PropTypes.string,
        showPopup: PropTypes.func.isRequired
    };

    static defaultProps = {
        ...SourceAddToCartContainer.defaultProps,
        list: '',
        added: false
    };

    state = {
        added: false,
        ...SourceAddToCartContainer.state
    };

    containerFunctions = {
        ...this.containerFunctions,
        afterAddToCart: this.afterAddToCart.bind(this)
    };

    containerProps() {
        const {
            product,
            showPopup,
            list,
            isLoading
        } = this.props;

        const {
            added
        } = this.state

        return {
            ...super.containerProps(),
            product,
            showPopup,
            added,
            list,
            isLoading
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { isAdding } = this.state;
        const { isAdding: prevIsAdding } = prevState;

        if (!isAdding && prevIsAdding) {
            this.afterAddToCart();
        }
    }

    getActionType = (fromPopup, fromConfigurator, fromAdo) => {
        if (fromAdo) {
            return 'corner_opportunities';
        } if (fromPopup) {
            return 'popup';
        } if (fromConfigurator) {
            return 'configurator';
        }

        return 'standard';
    };

    afterAddToCart() {
        const {
            // setQuantityToDefault,
            product,
            product: {
                sku, name, price_range: {
                    maximum_price: {
                        discount: { amount_off } = {},
                        final_price: { currency, value } = {}
                    } = {}
                } = {},
                mc_techspecs_template,
                categories
            },
            list,
            showPopup,
            pushAddToCart,
            fromPopup,
            decreaseLoading,
            isLoading,
            fromConfigurator,
            fromDC
        } = this.props;

        const limitedItem = BrowserDatabase.getItem('limitedItem');
        if (!fromPopup && !limitedItem && isLoading < 2) {
            showPopup(`${ADD_TO_CART_POPUP_ID_PREFIX}_${sku}`, { title: __('Cart'), product });
        }

        if (limitedItem) {
            showPopup(`${ADD_TO_CART_LIMITED}_${sku}`, { title: __('Warning'), product });
            BrowserDatabase.deleteItem('limitedItem');
        }

        // setQuantityToDefault();

        pushAddToCart(product, list);

        if (isLoading > 0) {
            decreaseLoading();
        }

        const fromAdo = categories && categories.find((elem) => elem.id === CATEGORY_ID_ANGOLO_DELLE_OCCASIONI) !== undefined;

        const action_type = this.getActionType(fromPopup, fromConfigurator, fromAdo);

        // EVENTI G4
        window?.dataLayer?.push({ ecommerce: null }); // Clear the previous ecommerce object.
        if (fromDC) {
            dataLayer.push({
                event: 'add_to_cart',
                action_type: 'dolce casa',
                ecommerce: {
                    value: 60,
                    currency: 'EUR',
                    items: [
                        {
                            item_id: 'FA4Y',
                            item_name: 'Servizio Dolce Casa',
                            affiliation: 'store online',
                            currency: 'EUR',
                            item_brand: 'Mondo Convenienza',
                            quantity: 1,
                            price: 60,
                            item_category: 'book_appointment'
                        }
                    ]
                }
            });
        } else {
            window?.dataLayer?.push({
                event: 'add_to_cart',
                action_type,
                ecommerce: {
                    value,
                    currency: 'EUR',
                    items: [
                        {
                            item_id: sku,
                            item_name: name,
                            affiliation: 'store online',
                            currency,
                            discount: amount_off,
                            // eslint-disable-next-line object-shorthand
                            item_brand: 'Mondo Convenienza',
                            item_category: mc_techspecs_template,
                            item_list_name: `Categoria ${ mc_techspecs_template}`,
                            // eslint-disable-next-line no-magic-numbers
                            price: value,
                            quantity: 1
                        }
                    ]
                }
            });
        }

        // this.removeProductFromWishlist();
        this.setState({ isLoading: false });
        this.setState({ added: true });
        setTimeout(() => {
            this.setState({ added: false });
        // eslint-disable-next-line no-magic-numbers
        }, 5000);
    }

    render() {
        return (
            <AddToCart
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
